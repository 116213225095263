<template>
  <div>
    <s-dialog :open="!!currentRole" to="stds-dialog-role-permission" size="md">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[63.2rem]">
        <st-dialog-header @clickClose="closeDialog">
          {{ getMemberRoleName(currentRole?.name) }}
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body class="pb-24">
            <m-table v-if="currentRole && currentRole.roles">
              <colgroup>
                <col style="width: auto" />
                <col style="width: 9.6rem" />
                <col style="width: 9.6rem" />
              </colgroup>
              <tr>
                <st-th :thTitle="$t('studio.member_manage.role_manage_create_role_menu_header1')" />
                <st-th
                  aCenter
                  :thTitle="$t('studio.member_manage.role_manage_create_role_perm_view_header2')"
                />
                <st-th
                  aCenter
                  :thTitle="$t('studio.member_manage.role_manage_create_role_perm_edit_header3')"
                />
              </tr>
              <tbody>
                <tr v-for="(item, index) in currentRole.roles" :key="`menu_role_${index}`">
                  <td colspan="3">
                    <div class="flex flex-col mt-4 gap-8">
                      <st-folding
                        :key="`${item.name}`"
                        v-model:isOpenDetail="item.expanded"
                        roleAdminType
                        :name="$t(getNameRoleAuthorityById(item.name))"
                        :isDisabled="item.childRoles?.length === 0"
                        :viewableCheck="viewableCheck(item)"
                        :editableCheck="editableCheck(item)"
                        :isEditableDisabled="currentRole?.isBasicRole || !viewableCheck(item)"
                      >
                        <div class="mb-4 ml-[3rem]">
                          <m-table>
                            <colgroup>
                              <col style="width: auto" />
                              <col style="width: 9.6rem" />
                              <col style="width: 9.6rem" />
                            </colgroup>
                            <tbody>
                              <tr
                                v-for="(sub, indexSub) in item.childRoles"
                                :key="`sub_${indexSub}`"
                              >
                                <td
                                  class="h-36 align-middle px-12 text-md text-left font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20"
                                >
                                  {{ $t(getNameRoleAuthorityById(sub.roleAuthorityId)) }}
                                </td>
                                <td
                                  class="h-36 align-middle px-12 text-md text-center font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20"
                                >
                                  <s-icon
                                    v-if="sub.viewable"
                                    icon="ic-v2-control-check-bold-line"
                                    size="3xl"
                                    class="text-on-surface-elevation-1 flex mx-auto"
                                  />
                                  <s-icon
                                    v-else
                                    icon="ic-v2-control-check-bold-line"
                                    size="3xl"
                                    class="text-disabled-variant-2 flex mx-auto"
                                  />
                                </td>
                                <td
                                  class="h-36 align-middle px-12 text-md text-center font-regular text-on-surface-elevation-3 first:pl-20 last:pr-20"
                                >
                                  <span
                                    v-if="
                                      currentRole.name !== ROLE_MARKETER &&
                                        (sub.roleAuthorityId === ROLE_ID.PRODUCT_STATISTICS ||
                                          sub.roleAuthorityId === ROLE_ID.PRODUCT_BILL_SETTLEMENT)
                                    "
                                    class="text-placeholder font-medium mr-8"
                                  >-</span>
                                  <template v-else>
                                    <s-icon
                                      v-if="sub.editable"
                                      icon="ic-v2-control-check-bold-line"
                                      size="3xl"
                                      class="text-on-surface-elevation-1 flex mx-auto"
                                    />
                                    <s-icon
                                      v-else
                                      icon="ic-v2-control-check-bold-line"
                                      size="3xl"
                                      class="text-disabled-variant-2 flex mx-auto"
                                    />
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </m-table>
                        </div>
                      </st-folding>
                    </div>
                  </td>
                </tr>
              </tbody>
            </m-table>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer />
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-role-permission" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { fetchRoleDetailApi } from '@/apis/member.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StFolding from '@/components/common/st-folding.vue';
import MTable from '@/components/common/table/m-table.vue';
import StTh from '@/components/common/table/st-th.vue';
import {
  ROLE_DEVELOPER,
  ROLE_ID,
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_OWNER,
  ROLE_PROJECT_MANAGER
} from '@/constants/permission.const';
import { createRoleFormMapper } from '@/mappers/member-mng.mapper';
import { useUserStore } from '@/stores/user.store';
import type {
  ChildRoleAuthority,
  CreateRoleRequest,
  RolesRequest
} from '@/types/role/role.request.type';
import { getNameRoleAuthorityById } from '@/utils/permission.util';

const props = defineProps<{
  roleNo: number;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const route = useRoute();
const groupId = route.params.id as string;
const userStore = useUserStore();

const { selectedGroupId } = storeToRefs(userStore);

const currentRole = ref<CreateRoleRequest>();

const getMemberRoleName = (roleName?: string) => {
  if (!roleName) {
    return '';
  }
  switch (roleName) {
    case ROLE_OWNER:
      return t('studio.gnb.group_myinfo.my_group_role_type_owner');
    case ROLE_DEVELOPER:
      return t('studio.member_manage.role_value_developer');
    case ROLE_MARKETER:
      return t('studio.member_manage.role_value_marketer');
    case ROLE_OPERATOR:
      return t('studio.member_manage.role_value_operator');
    case ROLE_PROJECT_MANAGER:
      return t('studio.member_manage.role_value_manager');
    default:
      return roleName;
  }
};

const closeDialog = () => {
  emit('close');
};

const editableCheck = (role: RolesRequest) => {
  if (role.childRoles && role.childRoles.length === 0) {
    return role.editable;
  }

  return role.childRoles?.every((subRole: ChildRoleAuthority) => subRole.editable);
};

const viewableCheck = (role: RolesRequest) => {
  if (role.childRoles && role.childRoles.length === 0) {
    return role.viewable;
  }

  return role.childRoles?.every((subRole: ChildRoleAuthority) => subRole.viewable);
};

const init = async () => {
  const res = await fetchRoleDetailApi({
    groupId: groupId || selectedGroupId.value,
    roleNo: props.roleNo
  });

  if (!res) {
    return;
  }

  currentRole.value = createRoleFormMapper(res);
};

init();
</script>
